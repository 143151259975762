.pt--tw-form .form-seperator {
  display: none;
  width: 2px;
  height: 25px;
  background-color: #29363E;
  margin: 15px auto;
}

.pt--tw-form .form-title-wrap.form-has-icon {
  padding-top: 38px;
  position: relative;
}

.pt--tw-form .form-title-wrap.form-has-icon .field--name-field-form-icon {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -38px;
  width: 76px;
  height: 76px;
}

.pt--tw-form .form-title-wrap .form-title {
  font-size: 1.5625rem;
}

@media (max-width: 1440px) {
  .pt--tw-form .form-title-wrap .form-title {
    font-size: calc(1.1125rem + 0.5vw) ;
  }
}

.pt--tw-form .form-intro {
  margin-bottom: 15px;
}

.pt--tw-form .form-intro + .form-seperator {
  margin-top: 0;
}

.pt--tw-form.width-narrow-content .form-title,
.pt--tw-form.width-narrow-content .form-intro {
  text-align: center;
}

.pt--tw-form.width-narrow-content .form-title + .form-intro {
  margin-top: -15px;
}

.pt--tw-form.width-narrow-content .form-seperator {
  display: block;
}

.pt--tw-form .help-text {
  margin-bottom: 0;
}

.pt--tw-form .help-text p:last-child {
  margin-bottom: 0;
}

.pt--tw-layout-row.content-light-on-dark-bg .pt--tw-form.paragraph .form-title,
.pt--tw-layout-row.content-dark-on-light-bg .pt--tw-form.paragraph .form-title {
  color: #29363E;
}

.content-light-on-dark-bg .pt--tw-form .form-title,
.content-dark-on-light-bg .pt--tw-form .form-title {
  padding: 15px;
  background-color: #FFD400;
  text-align: center;
  margin-bottom: 0;
}

.content-light-on-dark-bg .pt--tw-form .form-has-icon .form-title,
.content-dark-on-light-bg .pt--tw-form .form-has-icon .form-title {
  padding-top: 53px;
}

.content-light-on-dark-bg .pt--tw-form .form-wrap,
.content-dark-on-light-bg .pt--tw-form .form-wrap {
  background-color: #FFF;
  padding: 15px;
}

.content-light-on-dark-bg .pt--tw-form .help-text,
.content-dark-on-light-bg .pt--tw-form .help-text {
  font-size: 0.875rem;
  color: #B3B3B3;
}

.pg-has-bg-img.content-light-on-dark-bg .pt--tw-form,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form {
  color: #29363E;
}

.pg-has-bg-img.content-light-on-dark-bg .pt--tw-form h1, .pg-has-bg-img.content-light-on-dark-bg .pt--tw-form h2, .pg-has-bg-img.content-light-on-dark-bg .pt--tw-form h3, .pg-has-bg-img.content-light-on-dark-bg .pt--tw-form h4, .pg-has-bg-img.content-light-on-dark-bg .pt--tw-form h5,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form h1,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form h2,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form h3,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form h4,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form h5 {
  color: #29363E;
}

.pg-has-bg-img.content-light-on-dark-bg .pt--tw-form a:not(.btn),
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form a:not(.btn) {
  color: #D6AB0A;
}

.pg-has-bg-img.content-light-on-dark-bg .pt--tw-form a:not(.btn):focus, .pg-has-bg-img.content-light-on-dark-bg .pt--tw-form a:not(.btn):hover,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form a:not(.btn):focus,
.pg-has-bg-img.content-dark-on-light-bg .pt--tw-form a:not(.btn):hover {
  color: #58585b;
}

@media screen and (min-width: 992px) {
  .pt--tw-form.width-narrow-content .form-wrap {
    margin-left: 30px;
    margin-right: 30px;
  }
  .content-light-on-dark-bg .pt--tw-form .form-title {
    padding: 30px;
    background-color: #FFD400;
    color: #29363E;
    text-align: center;
  }
  .content-light-on-dark-bg .pt--tw-form .form-has-icon .form-title {
    padding-top: 68px;
  }
  .content-light-on-dark-bg .pt--tw-form .form-wrap {
    padding: 30px;
    background-color: #FFF;
  }
}

@media screen and (min-width: 1200px) {
  .pt--tw-form.width-narrow-content .form-wrap {
    margin-left: 60px;
    margin-right: 60px;
  }
}

@media screen and (min-width: 1500px) {
  .pt--tw-form.width-narrow-content .form-wrap {
    margin-left: 90px;
    margin-right: 90px;
  }
}

@media screen and (max-width: 767px) {
  .pg-has-bg-img.pt--tw-layout-row .has-mobile-col-bg.pt--tw-layout-col + .pt--tw-layout-col .pt--tw-form {
    margin-top: -38px;
  }
}
