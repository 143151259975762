@import "./_sass-essentials/essentials.scss";

.pt--tw-form {
  .form-seperator {
    display: none;
    width: 2px;
    height: 25px;
    background-color: $c-base-txt;
    margin: 15px auto;
  }

  .form-title-wrap {
    &.form-has-icon {
      padding-top: 38px;
      position: relative;

      .field--name-field-form-icon {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -38px;
        width: 76px;
        height: 76px;
      }
    }

    .form-title {
      @include resp-fs($fs-h2);
    }
  }

  .form-intro {
    margin-bottom: 15px;

    & + .form-seperator {
      margin-top: 0;
    }
  }

  &.width-narrow-content {
    .form-title,
    .form-intro {
      text-align: center;
    }

    .form-title + .form-intro {
      margin-top: -15px;
    }

    .form-seperator {
      display: block;
    }
  }

  .help-text {
    margin-bottom: 0;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pt--tw-layout-row.content-light-on-dark-bg &.paragraph,
  .pt--tw-layout-row.content-dark-on-light-bg &.paragraph {
    .form-title {
      color: $c-base-txt;
    }
  }

  .content-light-on-dark-bg &,
  .content-dark-on-light-bg & {
    .form-title {
      padding: $l-gutter-half;
      background-color: $c-brand-primary;
      text-align: center;
      margin-bottom: 0;
    }

    .form-has-icon .form-title {
      padding-top: 38px + $l-gutter-half;
    }

    .form-wrap {
      background-color: $c-white;
      padding: $l-gutter-half;
    }

    .help-text {
      @include resp-fs(14px);
      color: $c-gray--normal2;
    }
  }

  .pg-has-bg-img.content-light-on-dark-bg &,
  .pg-has-bg-img.content-dark-on-light-bg & {
    color: $c-base-txt;

    h1, h2, h3, h4, h5 {
      color: $c-base-txt;
    }

    a:not(.btn) {
      color: $c-base-link;

      &:focus,
      &:hover {
        color: $c-base-link-hov;
      }
    }
  }

  @include mq($bp-md-min) {
    &.width-narrow-content {
      .form-wrap {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    .content-light-on-dark-bg & {
      .form-title {
        padding: $l-gutter;
        background-color: $c-brand-primary;
        color: $c-base-txt;
        text-align: center;
      }

      .form-has-icon .form-title {
        padding-top: 38px + $l-gutter;
      }

      .form-wrap {
        padding: $l-gutter;
        background-color: $c-white;
      }
    }
  }

  @include mq($bp-lg-min) {
    &.width-narrow-content {
      .form-wrap {
        margin-left: 60px;
        margin-right: 60px;
      }
    }
  }

  @include mq($bp-xl-min) {
    &.width-narrow-content {
      .form-wrap {
        margin-left: 90px;
        margin-right: 90px;
      }
    }
  }
}

@include mq(false, $bp-xs-max) {
  .pg-has-bg-img.pt--tw-layout-row {
    .has-mobile-col-bg.pt--tw-layout-col + .pt--tw-layout-col {
      .pt--tw-form {
        margin-top: -38px;
      }
    }
  }
}
